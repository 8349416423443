import request from "@/utils/request";

// 获取常用联系人
export const contactList = (params) => {
  return request({
    url: "/we_chat/contact/list",
    method: "get",
    params,
  });
};

// 保存常用联系人
export const contactAddOrUpdate = (data) => {
  return request({
    url: "/we_chat/contact/save",
    method: "post",
    data,
  });
};

// 删除常用联系人
export const contactDelete = (id) => {
  return request({
    url: "/we_chat/contact/delete/" + id,
    method: "delete",
  });
};
