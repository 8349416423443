<template>
  <div class="commonlyUsed">
    <p class="takeCare">
      请注意：最多添加10人，添加成功不可以删除，只能修改手机号、姓名，请谨慎添加。
    </p>
    <van-button plain type="primary" class="btn" @click="add">
      <van-icon name="plus" /> 添加常用观众（最多10人）
    </van-button>
    <div v-show="list.length === 0" class="nothing">
      <img src="@/assets/images/nothing.png" alt="" />
      <span>暂无数据</span>
    </div>
    <van-checkbox-group
      v-model="selecteds"
      v-if="max > 0"
      :max="max"
      @change="checkChange"
    >
      <van-checkbox
        :name="item.id"
        v-for="(item, index) in list"
        :key="index"
        :disabled="item.dis"
      >
        <div class="info">
          <p class="name">{{ item.name }}</p>
          <p>证件号码：{{ item.documentNumber }}</p>
          <p>手机号码：{{ item.phone }}</p>
        </div>
        <img src="@/assets/images/edit_p.png" alt="" @click.stop="edit(item)" />
      </van-checkbox>
    </van-checkbox-group>
    <van-button
      type="primary"
      class="confirm_btn"
      v-if="max > 0"
      @click="confirm"
      ><span class="large">（请选择{{ max }}人）</span>确认选择<span
        class="large"
        >（{{ selecteds.length }}）</span
      >人</van-button
    >
    <div class="list_box" v-else>
      <div v-for="(item, index) in list" :key="index" class="item">
        <!-- <img src="@/assets/images/delete_p.png" alt="" @click="del(item.id)" /> -->
        <div class="right">
          <div class="info">
            <p class="name">{{ item.name }}</p>
            <p>证件号码：{{ item.documentNumber }}</p>
            <p>手机号码：{{ item.phone }}</p>
          </div>
          <img src="@/assets/images/edit_p.png" alt="" @click="edit(item)" />
        </div>
      </div>
    </div>
    <van-dialog
      v-model:show="show"
      v-if="show"
      :title="form.id ? '编辑观众' : '添加观众'"
      show-cancel-button
      :beforeClose="beforeClose"
      confirmButtonText="保存"
      confirmButtonColor="#458aef"
    >
      <van-form ref="infoForm">
        <van-cell-group inset>
          <van-field
            v-model="form.name"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '姓名不能为空' }]"
          />
          <van-field
            v-model="form.type"
            is-link
            readonly
            :disabled="disabled"
            label="证件类型"
            placeholder="点击选择证件类型"
            @click="disabled ? null : (showType = true)"
            :rules="[{ required: true, message: '证件类型不能为空' }]"
          />
          <van-field
            v-model="form.documentNumber"
            label="证件号码"
            placeholder="请输入证件号码"
            :disabled="disabled"
            :rules="[{ required: true, message: '证件号码不能为空' }]"
          />
          <van-field
            v-model="form.phone"
            label="手机号"
            placeholder="请输入手机号"
            :rules="[
              { required: true, message: '手机号不能为空' },
              {
                pattern:
                  /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                message: '请输入正确的手机号码',
              },
            ]"
          />
        </van-cell-group>
      </van-form>
    </van-dialog>
    <van-popup v-model:show="showType" position="bottom">
      <van-picker
        :columns="typeList"
        @confirm="onConfirm"
        @cancel="showType = false"
      />
    </van-popup>
    <van-dialog
      v-model:show="show2"
      title="温馨提示"
      show-cancel-button
      confirmButtonText="确认"
      confirmButtonColor="#458aef"
      @confirm="confirm2"
      class="confirm_tips"
    >
      <div class="content">
        最多添加<span style="color: red">10</span>个常用观众， 添加成功后<span
          style="color: red"
          >不可以删除，只能修改手机号、姓名</span
        >，确定要添加吗?
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { encrypt } from '@/utils/jsencrypt';

const types = [
  '',
  '身份证',
  '护照',
  '往来港澳通行证/港澳居民来往内地通行证',
  '往来台湾通行证/台湾居民来往大陆通行证',
  '外国人永久居留证（Foreign Permanent Resident ID Card）',
];
import {
  contactList,
  contactAddOrUpdate,
  contactDelete,
} from '@/api/CommonlyUsed';
export default {
  name: 'CommonlyUsed',
  data() {
    return {
      list: [],
      selecteds: [],
      show: false,
      form: {},
      showType: false,
      typeList: [
        '身份证',
        '护照',
        '往来港澳通行证/港澳居民来往内地通行证',
        '往来台湾通行证/台湾居民来往大陆通行证',
        '外国人永久居留证（Foreign Permanent Resident ID Card）',
      ],
      show2: false,
      disabled: false,
    };
  },
  created() {
    this.getList();
  },
  props: {
    max: {
      type: Number,
      default: () => 0,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    disableds: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    ids(value) {
      this.selecteds = value;
    },
    disableds(value) {
      this.list.map((item) => {
        item.dis = false;
        value.map((item2) => {
          if (item.id === item2) {
            item.dis = true;
          }
        });
      });
    },
    'form.documentNumber'(value) {
      if (value) {
        this.form.documentNumber = value.toUpperCase();
      }
    },
  },
  mounted() {},
  methods: {
    checkChange(value) {
      if (value.length >= this.max) {
        this.$toast(
          '已选择' + value.length + '人，如需更换，请先取消选择再进行勾选。'
        );
      }
    },
    add() {
      if (this.list.length < 10) {
        this.disabled = false;
        this.show = true;
      } else {
        this.$notify({
          type: 'danger',
          message: '最多添加10人',
        });
      }
    },
    async getList() {
      let { data } = await contactList();
      data.map((item) => {
        item.type = types[item.documentType];
        item.dis = false;
        this.disableds.map((item2) => {
          if (item.id === item2) {
            item.dis = true;
          }
        });
      });
      this.list = data;
    },
    del(id) {
      this.$dialog
        .confirm({
          title: '确认提示',
          message: '确认删除这个联系人吗？',
        })
        .then(async () => {
          await contactDelete(id);
          this.getList();
        })
        .catch(() => {});
    },
    edit(item) {
      this.disabled = true;
      this.form = { ...item };
      this.show = true;
    },
    beforeClose(action) {
      if (action === 'confirm') {
        this.$refs.infoForm
          .validate()
          .then(() => {
            this.show2 = true;
          })
          .catch(() => {
            return false;
          });
      } else {
        this.form = {};
        return true;
      }
    },
    onConfirm(value) {
      this.form.type = value;
      types.map((item, index) => {
        if (item === value) {
          this.form.documentType = index;
        }
      });
      this.showType = false;
    },
    confirm() {
      this.$emit('personnel', this.selecteds);
      this.selecteds = [];
    },
    async confirm2() {
      //拷贝
      let form = { ...this.form };
      // 加密身份证
      form.documentNumber = encrypt(form.documentNumber);
      await contactAddOrUpdate(form);
      this.getList();
      this.show = false;
      this.form = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.commonlyUsed {
  overflow-y: auto;
  padding: 10px;
  background-color: #f5f6fa;
  min-height: calc(100vh - 66px);
  .takeCare {
    font-size: 12px;
    color: red;
    padding: 0 0 10px 0;
  }
  .btn {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .confirm_btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    .large {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .nothing {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    img {
      width: 60%;
    }
    span {
      font-size: 16px;
      color: #999;
    }
  }
  ::v-deep .van-checkbox-group {
    margin-bottom: 45px;
  }
  ::v-deep .van-checkbox-group,
  .list_box {
    .van-checkbox,
    .item {
      height: 90px;
      border: 1px solid #dbdbdb;
      background-color: #fff;
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        display: block;
      }
      .van-checkbox__label,
      .right {
        width: 100%;
        padding: 0 10px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .info {
          p {
            font-size: 13px;
            line-height: 20px;
          }
          .name {
            font-size: 15px;
            line-height: 24px;
          }
        }
      }
    }
  }
  ::v-deep .van-popup {
    .van-dialog__header {
      padding: 10px 0;
    }
    .van-cell__title {
      width: 70px;
    }
  }
  .confirm_tips {
    .content {
      font-size: 14px;
    }
  }
}
</style>
