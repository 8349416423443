import CryptoJS from "crypto-js";

// aes加密
const AES_KEY = "wzstm_security_@";

// 加密
export function encrypt(txt) {
  // AES加密
  const result = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse(AES_KEY), {
    mode: CryptoJS.mode.ECB,
  });
  // base64转码
  return CryptoJS.enc.Base64.stringify(result.ciphertext);
}
